import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import "../styles/slider.less";


export default function Slider({ children }) {
    return (
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
        >
            {children}
        </Swiper>
    );
}

export const Slide = SwiperSlide;