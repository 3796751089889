import React, { useEffect } from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useIntl } from "react-intl";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import AppButtons from "../components/AppButtons";
import Slider, { Slide } from "../components/Slider";
import "../styles/home.less";
import bannerImage from"../images/device-image.png";

const IndexPage = () => {
    const intl = useIntl();
    
    const onChangeDisplayMode = (e) => {
        const location = window.location;

        if (e.matches && !location.pathname.startsWith('/mbox/')) {
            location.href = location.origin + '/mbox/';
        }
    }


    useEffect(() => {
        document.body.classList.add("home");
        return () => document.body.classList.remove("home");
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(display-mode: standalone)');
        mediaQuery.addEventListener('change', onChangeDisplayMode);
        
        return () => mediaQuery.removeEventListener('change', onChangeDisplayMode);
    }, []);
    
    
    return (
        <Layout>
            <main>
              <section className="main-banner-device">                
                  {/* <StaticImage className="device-image" quality={100} src="../images/device-image.png" alt="" /> */}
                  <img className="device-image" src={bannerImage} />
              </section>

              <AppButtons className="app-buttons mobile-only" />

              <section id="advantages" className="advantages">
                <div className="row">
                  <h2>{intl.formatMessage({ id: "advantages" })}</h2>
                  <div  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "advantages.content" })
                  }} />

                  <div className="advantages-list">
                    <div className="advantages-item">
                      <StaticImage className="advantages-item-img" src="../images/incognito-off.svg" alt="" />
                      <p dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "advantages.item1" })
                      }} />
                    </div>
                    <div className="advantages-item">
                      <StaticImage className="advantages-item-img" src="../images/script-text-key-outline.svg" alt="" />
                      <p dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "advantages.item2" })
                      }} />
                    </div>
                    <div className="advantages-item">
                      <StaticImage className="advantages-item-img" src="../images/shield-account-outline.svg" alt="" />
                      <p dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "advantages.item3" })
                      }} />
                    </div>
                  </div>
                </div>
              </section>

              <section id="screenshots" className="screenshots">
                <div className="row">
                  <h2>{intl.formatMessage({ id: "screenshots" })}</h2>
                  <p dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "screenshots.content" })
                  }} />
                </div>

                <div className="slider-wrap">
                  <div className="row">
                    <Slider>                          
                          {/* <Slide><StaticImage src="../images/slider/sc-00.jpg" alt="" /></Slide> */}
                          <Slide><StaticImage src="../images/slider/sc-01.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-02.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-03.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-04.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-05.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-06.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-07.jpg" alt="" /></Slide>
                          <Slide><StaticImage src="../images/slider/sc-08.jpg" alt="" /></Slide>
                      </Slider>   
                    </div>
                  </div>
              </section>

              <section id="prices" className="prices">
                <div className="row">
                  <h2 dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "prices" })
                  }} />
                  <div dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "prices.content" })
                  }} />


                  <table>
                    <thead>
                      <tr>
                          <th></th>
                          <th>{intl.formatMessage({ id: "free" })}</th>
                          <th>{intl.formatMessage({ id: "standard" })}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>{intl.formatMessage({ id: "table.users" })}</td>
                          <td>1</td>
                          <td>1</td>
                      </tr>
                      <tr>
                          <td>{intl.formatMessage({ id: "table.storage" })}</td>
                          <td>500 MB</td>
                          <td>5 GB</td>
                      </tr>     
                      <tr>
                          <td>{intl.formatMessage({ id: "table.addresses" })}</td>
                          <td>1</td>
                          <td>1</td>
                      </tr> 
                      <tr>
                          <td>{intl.formatMessage({ id: "table.messages" })}</td>
                          <td>150</td>
                          <td>1000</td>
                      </tr> 
                      <tr>
                          <td>{intl.formatMessage({ id: "table.folders" })}</td>
                          <td className="positive">{intl.formatMessage({ id: "unlimited" })}</td>
                          <td className="positive">{intl.formatMessage({ id: "unlimited" })}</td>
                      </tr> 
                      <tr>
                          <td>{intl.formatMessage({ id: "table.imap" })}</td>
                          <td>{intl.formatMessage({ id: "yes" })}</td>
                          <td>{intl.formatMessage({ id: "yes" })}</td>
                      </tr>  
                      <tr>
                          <td>{intl.formatMessage({ id: "table.support" })}</td>
                          <td>{intl.formatMessage({ id: "limited" })}</td>
                          <td>{intl.formatMessage({ id: "normal" })}</td>
                      </tr>      
                                                           
                      <tr className="table-footer">
                        <td>{intl.formatMessage({ id: "table.pricing" })}</td>
                        <td>{intl.formatMessage({ id: "free" })}</td>
                        <td>CHF 5.00 /mo<br />CHF 45.00 /yr</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td><a href="/mbox/index.php/login/oauth" className="button">{intl.formatMessage({ id: "button.start" })}</a></td>
                        <td><a href="https://accounts.swisscows.com/subscriptions/mail " className="button">{intl.formatMessage({ id: "button.order" })}</a></td>                  
                      </tr>
                    </tfoot>
                  </table>


                  {/* <div className="beta-testing">
                    <p><em>*</em> <span dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "beta-phase" })
                    }} /></p>
                    <div className="beta-phase-content">
                      <StaticImage className="beta-phase-image" src="../images/beta.svg" alt="" />
                      <div dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "beta-phase.content" })
                      }} />
                    </div>
                  </div> */}
                </div>
              </section>

                
              <section className="help">
                <div className="row">
                  <h2>{intl.formatMessage({ id: "quick-start.title" })}</h2>
                  <div dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "quick-start.content" })
                  }} />                  
                </div>
              </section>
            </main>
        </Layout>
    )
}

export default IndexPage;